import React from "react";
import {useTranslation} from "react-i18next";

const Banner = (
    {
        title,
        classNames,
        href,
        content
    }) => {

    const {t} = useTranslation();
    const contentText = content || t('index.hero.subtitle');

    return (
        <section id="banner" className={`major ${classNames}`}>
            <div className="inner">
                <header className="major">
                    <h1>{title}</h1>
                </header>
                <div className="content">
                    <p>
                        {contentText}
                    </p>
                    {!!href && <ul className="actions">
                        <li>
                            <a href={href} className="button next scrolly">
                                {t('buttons.read-more')}
                            </a>
                        </li>
                    </ul>}
                </div>
            </div>
        </section>
    )
};

export default Banner;
