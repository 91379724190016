import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Banner from "../components/Banner";
import {Link} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";

const Mentoring = () => {

    const {t} = useTranslation();

    return (
        <Layout>
            <SEO/>
            <Banner title={t('mentoring.title')} classNames={'is-green'} content={t('mentoring.subtitle')}/>
            <div id="main">
                <section className="inner">
                    <header className="major">
                        <h2>{t('mentoring.title-extended')}</h2>
                    </header>
                    <p>
                        Spotkajmy się na indywidualnych konsultacjach online. Poprowadzę Cię przez proces nauki tworzenia aplikacji webowych i stron internetowych.
                    </p>
                    <p>
                        Znasz już podstawy? Porozmawiajmy o konkretnych problemach z którymi chciałbyś się zmierzyć.
                    </p>
                    <p>
                        Masz pomysł na projekt ale nie wiesz jak go zrealizować? Pomogę Ci skonsultować go pod kątem technologicznym.
                    </p>
                    <ul className="actions" style={{marginTop: '20px'}}>
                        <li>
                            <Link to="/#contact" className="button next scrolly">
                                {t('buttons.contact')}
                            </Link>
                        </li>
                    </ul>
                </section>
            </div>
        </Layout>
    );
}

export default Mentoring;